
import type { FetchOptions } from 'ofetch'

export const useCustomFetch = async (endpoint: string, opts?: FetchOptions) => {
    const nuxtApp = useNuxtApp()

    const headers: HeadersInit = {
        ...(opts?.headers || {})
    }

    const method: "GET" | "HEAD" | "PATCH" | "POST" | "PUT" | "DELETE" | "CONNECT" | "OPTIONS" | "TRACE" =
        (opts && opts.method && opts.method.toUpperCase()) as
            "GET" | "HEAD" | "PATCH" | "POST" | "PUT" | "DELETE" | "CONNECT" | "OPTIONS" | "TRACE"

    return await $fetch(endpoint, {
        ...opts,
        method,
        headers,
    }).catch(e => {
        if (e.status === 401) {
            nuxtApp.runWithContext(() => navigateTo('/logout'))
        }
    })
}
